import { graphql } from "gatsby"
import React from "react"
import "../assets/styles/index.scss"

import Scroll from "../components/global/scroll"
import TopNavigation from "../components/global/top_navigation"
import MainLayout from "../layouts/main_layout"

import Footer from "../components/footer/"
import Head from "../components/global/head"
import styles from "./form.module.scss"

const Application = () => {
  return (
    <MainLayout>
      <Head
        description="We're sorry. The page you're looking for does not exist."
        title="Page not found!"
        url="https://hardhat.com.au/404"
        image="https://hardhat.com.au/images/og.jpg"
      />
      <TopNavigation theme="dark" isHome={false} hideChat={true} />
      <div
        className={`${styles.content} ${
          styles.contentAlt
        } container text-center`}
      >
        <h1 className="mega1" style={{ marginBottom: "5vh" }}>
          It's not you,
          <br />
          it's us.
        </h1>
        <p className="body1">
          We're sorry. The page you're looking for does not exist.
          <br />
          That's not to say it never will. Websites are&nbsp;hard.
        </p>
      </div>
      <Footer />
    </MainLayout>
  )
}
export default Application
